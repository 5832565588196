<template>
  <div class="container mt-3 mb-5">
    <nav aria-label="breadcrumb" class="py-2">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="cursor: pointer">
          <router-link to="/" class="text-black"
            ><i class="fas fa-home"></i
          ></router-link>
        </li>
        <li class="breadcrumb-item active" style="cursor: pointer">
          Hubungi Kami
        </li>
      </ol>
    </nav>
    <div class="p-5 bg-white rounded box">
      <h2 class="mb-4">Hubungi Kami</h2>

      <h6 class="mb-4">Sebarang kemusykilan dan pertanyaan, sila hubungi:</h6>

      <p class="mb-4">
        Tabung Haji Contact Centre (THCC)<br />
        Telefon: <b>+603 - 6207 1919</b><br />
        Faks: <b>+603 - 7728 4959</b><br />
        Emel: <b>th-info@lth.gov.my</b><br />
      </p>

      <!-- <p class="mb-0">SMS : TH sebarang pertanyaan dan hantarkan ke <b>33990</b></p>
      <p class="text-muted mb-4">*setiap jawapan akan dicaj RM0.50</p> -->

      <p class="mb-0 mt-4">Waktu Operasi : <b>8:00 pagi - 6:30 petang</b></p>
      <p class="text-muted">
        (Beroperasi pada hari Isnin hingga Ahad. Kecuali pada cuti umum mengikut
        Wilayah Persekutuan, Kuala Lumpur)
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "contactUs",
};
</script>

<style scoped>
.box {
  position: relative;
  display: block;
  min-height: calc(100vh - 270px);
}

p, li {
  font-size: 18px !important;
  line-height: 28px;
}
</style>